import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const ArtistListContainer = styled.h2`
  font-size: 70px;
  line-height: 87px;

  padding: ${(props) => props.padding};

  & a:visited {
    color: inherit;
  }

  & a:hover {
    color: #fa3c44;
  }

  @media (max-width: 950px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (max-width: 500px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 400px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const ArtistList = ({ text, padding, link }) => {
  return (
    <ArtistListContainer padding={padding}>
      <Link to={link}>{text.text}</Link>
    </ArtistListContainer>
  );
};

export default ArtistList;
