import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';
import ArtistList from '../components/Text/artistList';

// Previews
import { withPreview } from 'gatsby-source-prismic';

const ArtistContainer = styled.div`
  padding: 100px 0;

  @media (max-width: 767px) {
    padding: 50px 0;
  }
`;

const Artists = ({ data }) => {
  const artistList = data.allPrismicArtist.edges.map((artist) => {
    return (
      <ArtistList
        text={artist.node.data.name}
        padding={0}
        link={'/artists/' + artist.node.uid}
        key={artist.node.prismicId}
      />
    );
  });

  return (
    <>
      <Helmet title={'Artists – Everyone Agency'} />
      <Col col={12}>
        <ArtistContainer>{artistList}</ArtistContainer>
      </Col>
    </>
  );
};
export default withPreview(Artists);

export const pageQuery = graphql`
  query ArtistsQuery {
    allPrismicArtist(sort: { fields: data___name___text, order: ASC }) {
      edges {
        node {
          uid
          prismicId
          data {
            name {
              text
            }
          }
        }
      }
    }
  }
`;
